<section class="about-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-image">
                    <img src="assets/img/about/about1.jpg" class="shadow" alt="image">
                    <img src="assets/img/about/about2.jpg" class="shadow" alt="image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <span class="sub-title">Welcome </span>
                    <h2>St. Michael Matriculation Higher Secondary School</h2>
                    <h6>“Desires dictate our priorities, Priorities shape our choices,
                        And Choices determine our actions.”
                       </h6>
                    <p>We strongly believe that every child is potentially the light and salt of the world and we strive hard to inspire every child to personify themselves to be the essence of life. From being to becoming a useful and productive member of the society is our see popes of education.</p>
                    <div class="features-text">
                        <h5><i class='bx bx-planet'></i>A place where you can achieve</h5>
                        <p>We are happy that you have taken the right choice and now your priority becomes ours.</p>
                    </div>
                    <a routerLink="/courses-2-columns-style-1" class="default-btn"><i class='bx bx-move-horizontal icon-arrow before'></i><span class="label">View Courses</span><i class="bx bx-move-horizontal icon-arrow after"></i></a>
                </div>
            </div>
        </div>
    </div>
</section>

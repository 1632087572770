<app-header-style-one></app-header-style-one>

<app-homefive-main-banner></app-homefive-main-banner>
<section class="partner-area pb-100">

</section>

<app-features></app-features>
<section class="partner-area pb-100">

</section>

<section class="instructor-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Make Connections</span>
            <h2>Our Management</h2>

        </div>

        <app-instructors-style-one></app-instructors-style-one>
    </div>

    <div id="particles-js-circle-bubble-3"></div>
</section>


<section class="partner-area pb-100">
    <div class="container">
        <div class="section-title">
            <h2>Our Placement Partners</h2>
        </div>

        <app-partner-style-two></app-partner-style-two>
    </div>
</section>

<section class="feedback-area ptb-100">
    <div class="container">
        <app-feedback-style-one></app-feedback-style-one>
    </div>
</section>





<section class="testimonials-area pt-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Testimonials</span>
            <h2>What Students Says</h2>
        </div>

        <app-feedback-style-two></app-feedback-style-two>
    </div>
</section>


<app-become-instructor-partner></app-become-instructor-partner>

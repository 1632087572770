<div class="testimonials-slides owl-carousel owl-theme">
    <div class="single-testimonials-item">
        <p>I personally feel, getting placement is one of the toughest things for any engineering graduate. But for the students of our college, it is like a bed of roses. The college gave a platform to build my personality, team work, leadership skills and can grow for higher heights. The placement cell has expanded my career possibilities by giving me the knowledge towards a focused career path based on specialized skills.</p>
        <div class="info">
            <img src="assets/img/user5.jpg" class="shadow rounded-circle" alt="image">
            <h3>Nithyaselvi </h3>
            <span>Student</span>
        </div>
    </div>

 <div class="single-testimonials-item">
        <p>The college regularly organizes workshops and competitions to help students learn new skills. Faculty members are very supportive and take great initiative to inculcate knowledge and skills in students. Our college provides facilities like free Wi-Fi , library, labs with all modern equipment. The college also has an EDC (Entrepreneurship Development Cell) for the students aspiring to start their businesses.</p>
        <div class="info">
            <img src="assets/img/user7.jpg" class="shadow rounded-circle" alt="image">
            <h3>Prakash Kumar</h3>
            <span>Student</span>
        </div>
    </div>

    <div class="single-testimonials-item">
        <p>When I was studying in FMCET, I have witnessed a tremendous change in my life both in academic and personal level. The campus, surrounded by beautiful greenery provided an ideal platform for growth and improvement. In a short span of time, the college has already produced exceptional results and continues to improve the quality of education.</p>
        <div class="info">
            <img src="assets/img/user4.jpg" class="shadow rounded-circle" alt="image">
            <h3>David </h3>
            <span>Student</span>
        </div>
    </div>

    <div class="single-testimonials-item">
        <p>Apart from learning, we had an exposure to tremendous events in and out of the college. It helped me to nurture my talents too. We had an amazing placement cell that prepares us to be bold and confident enough to appear for the placement process and to give our best. An amazing four years of experience at this college. I miss this place.</p>
        <div class="info">
            <img src="assets/img/user6.jpg" class="shadow rounded-circle" alt="image">
            <h3>Priyadharshini</h3>
            <span>Student</span>
        </div>
    </div>
</div>

<div class="instructor-slides owl-carousel owl-theme">
    <div class="single-instructor-box mb-30">
        <div class="image">
            <img src="assets/img/instructor/instructor1.jpg" alt="image">

            <ul class="social">
                <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                <li><a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>
            </ul>
        </div>

        <div class="content">
            <h3><a routerLink="/single-instructor">Dr. V. Michael</a></h3>
            <span>Founder</span>
        </div>
    </div>

    <div class="single-instructor-box mb-30">
        <div class="image">
            <img src="assets/img/instructor/instructor2.jpg" alt="image">

            <ul class="social">
                <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                <li><a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>
            </ul>
        </div>

        <div class="content">
            <h3><a routerLink="/single-instructor">Dr. M. Stalin Arockiyaraj</a></h3>
            <span>CHARIMAN</span>
        </div>
    </div>

    <div class="single-instructor-box mb-30">
        <div class="image">
            <img src="assets/img/instructor/instructor3.jpg" alt="image">

            <ul class="social">
                <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                <li><a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>
            </ul>
        </div>

        <div class="content">
            <h3><a routerLink="/single-instructor">Dr. J. Bridget Nirmala</a></h3>
            <span>CEO</span>
        </div>
    </div>

    <div class="single-instructor-box mb-30">
        <div class="image">
            <img src="assets/img/instructor/instructor4.jpg" alt="image">

            <ul class="social">
                <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                <li><a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>
            </ul>
        </div>

        <div class="content">
            <h3><a routerLink="/single-instructor">Mrs. A. Fatima</a></h3>
            <span>SECRETARY </span>
        </div>
    </div>
</div>

<app-header-style-two></app-header-style-two>

<div class="page-title-area item-bg2 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a href="index.html">Home</a></li>
                <li>FAQ's</li>
            </ul>
            <h2>Frequently Asked Question</h2>
        </div>
    </div>
</div>

<section class="faq-area pt-100 pb-70">
    <div class="container">
        <div class="tab faq-accordion-tab">
            <ul class="tabs d-flex flex-wrap justify-content-center">
                <li><a href="#"><i class='bx bx-flag'></i> <span>Engineering College</span></a></li>
                <li><a href="#"><i class='bx bxs-badge-dollar'></i> <span>Polytechnic College</span></a></li>
                <li><a href="#"><i class='bx bx-shopping-bag'></i> <span>Schools</span></a></li>
                <li><a href="#"><i class='bx bx-book-open'></i> <span>B.Ed Colleges</span></a></li>
                <li><a href="#"><i class='bx bx-info-circle'></i> <span>Teacher Training </span></a></li>
            </ul>

            <div class="tab-content">
                <div class="tabs-item">
                    <div class="row">
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: stahya the difference between college and university?</h3>
                                <p><strong>A:</strong> Colleges offer only undergraduate degrees while universities offer graduate degrees as well, but the terms are often used interchangeably.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: What are the different types of undergraduate degrees?</h3>
                                <p><strong>A:</strong> Colleges offer only undergraduate degrees while universities offer graduate degrees as well, but the terms are often used interchangeably.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: What are the different types of  graduate degrees?</h3>
                                <p><strong>A:</strong> Colleges offer only undergraduate degrees while universities offer graduate degrees as well, but the terms are often used interchangeably.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: Can you work while studying in the United States?</h3>
                                <p><strong>A:</strong> Colleges offer only undergraduate degrees while universities offer graduate degrees as well, but the terms are often used interchangeably.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: What is distance education?</h3>
                                <p><strong>A:</strong> Colleges offer only undergraduate degrees while universities offer graduate degrees as well, but the terms are often used interchangeably.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: What do I need to know about mobile?</h3>
                                <p><strong>A:</strong> Colleges offer only undergraduate degrees while universities offer graduate degrees as well, but the terms are often used interchangeably.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: What is content marketing?</h3>
                                <p><strong>A:</strong> Colleges offer only undergraduate degrees while universities offer graduate degrees as well, but the terms are often used interchangeably.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: Do I need a blog?</h3>
                                <p><strong>A:</strong> Colleges offer only undergraduate degrees while universities offer graduate degrees as well, but the terms are often used interchangeably.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tabs-item">
                    <div class="row">
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: sumi the difference between college and university?</h3>
                                <p><strong>A:</strong> Colleges offer only undergraduate degrees while universities offer graduate degrees as well, but the terms are often used interchangeably.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: What are the different types of undergraduate degrees?</h3>
                                <p><strong>A:</strong> Colleges offer only undergraduate degrees while universities offer graduate degrees as well, but the terms are often used interchangeably.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: What are the different types of  graduate degrees?</h3>
                                <p><strong>A:</strong> Colleges offer only undergraduate degrees while universities offer graduate degrees as well, but the terms are often used interchangeably.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: Can you work while studying in the United States?</h3>
                                <p><strong>A:</strong> Colleges offer only undergraduate degrees while universities offer graduate degrees as well, but the terms are often used interchangeably.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: What is distance education?</h3>
                                <p><strong>A:</strong> Colleges offer only undergraduate degrees while universities offer graduate degrees as well, but the terms are often used interchangeably.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: What do I need to know about mobile?</h3>
                                <p><strong>A:</strong> Colleges offer only undergraduate degrees while universities offer graduate degrees as well, but the terms are often used interchangeably.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: What is content marketing?</h3>
                                <p><strong>A:</strong> Colleges offer only undergraduate degrees while universities offer graduate degrees as well, but the terms are often used interchangeably.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: Do I need a blog?</h3>
                                <p><strong>A:</strong> Colleges offer only undergraduate degrees while universities offer graduate degrees as well, but the terms are often used interchangeably.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tabs-item">
                    <div class="row">
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: What’s the difference between college and university?</h3>
                                <p><strong>A:</strong> Colleges offer only undergraduate degrees while universities offer graduate degrees as well, but the terms are often used interchangeably.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: What are the different types of undergraduate degrees?</h3>
                                <p><strong>A:</strong> Colleges offer only undergraduate degrees while universities offer graduate degrees as well, but the terms are often used interchangeably.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: What are the different types of  graduate degrees?</h3>
                                <p><strong>A:</strong> Colleges offer only undergraduate degrees while universities offer graduate degrees as well, but the terms are often used interchangeably.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: Can you work while studying in the United States?</h3>
                                <p><strong>A:</strong> Colleges offer only undergraduate degrees while universities offer graduate degrees as well, but the terms are often used interchangeably.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: What is distance education?</h3>
                                <p><strong>A:</strong> Colleges offer only undergraduate degrees while universities offer graduate degrees as well, but the terms are often used interchangeably.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: What do I need to know about mobile?</h3>
                                <p><strong>A:</strong> Colleges offer only undergraduate degrees while universities offer graduate degrees as well, but the terms are often used interchangeably.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: What is content marketing?</h3>
                                <p><strong>A:</strong> Colleges offer only undergraduate degrees while universities offer graduate degrees as well, but the terms are often used interchangeably.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: Do I need a blog?</h3>
                                <p><strong>A:</strong> Colleges offer only undergraduate degrees while universities offer graduate degrees as well, but the terms are often used interchangeably.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tabs-item">
                    <div class="row">
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: What’s the difference between college and university?</h3>
                                <p><strong>A:</strong> Colleges offer only undergraduate degrees while universities offer graduate degrees as well, but the terms are often used interchangeably.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: What are the different types of undergraduate degrees?</h3>
                                <p><strong>A:</strong> Colleges offer only undergraduate degrees while universities offer graduate degrees as well, but the terms are often used interchangeably.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: What are the different types of  graduate degrees?</h3>
                                <p><strong>A:</strong> Colleges offer only undergraduate degrees while universities offer graduate degrees as well, but the terms are often used interchangeably.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: Can you work while studying in the United States?</h3>
                                <p><strong>A:</strong> Colleges offer only undergraduate degrees while universities offer graduate degrees as well, but the terms are often used interchangeably.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: What is distance education?</h3>
                                <p><strong>A:</strong> Colleges offer only undergraduate degrees while universities offer graduate degrees as well, but the terms are often used interchangeably.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: What do I need to know about mobile?</h3>
                                <p><strong>A:</strong> Colleges offer only undergraduate degrees while universities offer graduate degrees as well, but the terms are often used interchangeably.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: What is content marketing?</h3>
                                <p><strong>A:</strong> Colleges offer only undergraduate degrees while universities offer graduate degrees as well, but the terms are often used interchangeably.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: Do I need a blog?</h3>
                                <p><strong>A:</strong> Colleges offer only undergraduate degrees while universities offer graduate degrees as well, but the terms are often used interchangeably.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tabs-item">
                    <div class="row">
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: What’s the difference between college and university?</h3>
                                <p><strong>A:</strong> Colleges offer only undergraduate degrees while universities offer graduate degrees as well, but the terms are often used interchangeably.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: What are the different types of undergraduate degrees?</h3>
                                <p><strong>A:</strong> Colleges offer only undergraduate degrees while universities offer graduate degrees as well, but the terms are often used interchangeably.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: What are the different types of  graduate degrees?</h3>
                                <p><strong>A:</strong> Colleges offer only undergraduate degrees while universities offer graduate degrees as well, but the terms are often used interchangeably.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: Can you work while studying in the United States?</h3>
                                <p><strong>A:</strong> Colleges offer only undergraduate degrees while universities offer graduate degrees as well, but the terms are often used interchangeably.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: What is distance education?</h3>
                                <p><strong>A:</strong> Colleges offer only undergraduate degrees while universities offer graduate degrees as well, but the terms are often used interchangeably.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: What do I need to know about mobile?</h3>
                                <p><strong>A:</strong> Colleges offer only undergraduate degrees while universities offer graduate degrees as well, but the terms are often used interchangeably.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: What is content marketing?</h3>
                                <p><strong>A:</strong> Colleges offer only undergraduate degrees while universities offer graduate degrees as well, but the terms are often used interchangeably.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="faq-item">
                                <h3>Q: Do I need a blog?</h3>
                                <p><strong>A:</strong> Colleges offer only undergraduate degrees while universities offer graduate degrees as well, but the terms are often used interchangeably.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<app-become-instructor-partner></app-become-instructor-partner>

<div class="row">
    <div class="col-lg-4 col-md-6 col-sm-6">
        <div class="single-team-member mb-30">
            <div class="member-image">
                <img src="assets/img/instructor/instructor2.jpg" alt="images">
            </div>

            <div class="member-content">
                <h3><a routerLink="/single-instructor">Dr. M. Stalin Arockiya Raj</a></h3>
                <span>CHARIMAN</span>
                <p></p>
                <ul class="social">
                    <li><a href="#" class="facebook" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                    <li><a href="#" class="twitter" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                    <li><a href="#" class="instagram" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                    <li><a href="#" class="linkedin" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                </ul>
            </div>
        </div>
    </div>

    <div class="col-lg-4 col-md-6 col-sm-6">
        <div class="single-team-member mb-30">
            <div class="member-image">
                <img src="assets/img/instructor/instructor3.jpg" alt="images">
            </div>

            <div class="member-content">
                <h3><a routerLink="/single-instructor">Dr. J. Bridget Nirmala</a></h3>
                <span>CEO</span>
                <p></p>
                <ul class="social">
                    <li><a href="#" class="facebook" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                    <li><a href="#" class="twitter" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                    <li><a href="#" class="instagram" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                    <li><a href="#" class="linkedin" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                </ul>
            </div>
        </div>
    </div>

    <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
        <div class="single-team-member mb-30">
            <div class="member-image">
                <img src="assets/img/instructor/instructor4.jpg" alt="images">
            </div>

            <div class="member-content">
                <h3><a routerLink="/single-instructor">Mrs. A. Fatima</a></h3>
                <span>SECRETARY</span>
                <p></p>
                <ul class="social">
                    <li><a href="#" class="facebook" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                    <li><a href="#" class="twitter" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                    <li><a href="#" class="instagram" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                    <li><a href="#" class="linkedin" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                </ul>
            </div>
        </div>
    </div>
</div>

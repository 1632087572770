<div class="container">
    <div class="row">
        <div class="col-lg-4 col-md-12">
            <div class="section-title text-left">
                <h2>Our Story</h2>
            </div>
        </div>

        <div class="col-lg-8 col-md-12">
            <div class="story-content">
                <h3>A better future starts here</h3>
                <p>An investment in knowledge always pays the best interest. St.Michael Group of Institutions is one of the best educational institutes of Tamilnadu where students can invest their learning period and will reap great knowledge out of it. W invite aspiring young minds to join us and step out into the real world as a true professional.</p>
                <h3>A Classical Education for the Future</h3>
                <p>St.Michael Group of Institutions is purely focused to increase the level of education. The institutes are the compilation of hard work and smart work from the dedicated team, which is only aiming to nourish the society with the best outcomes in the form of students who are the building blocks of the nation.</p>
                <h3>A Journey to Excellence</h3>
                <p>We believe that the most promising opportunities for discovery exist at the intersections of disciplines, and that the technologies of the next century will grow out of multidisciplinary partnerships. We are committed to excellence in all its endeavors.</p>
            </div>
        </div>
    </div>
</div>

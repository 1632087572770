<app-header-style-one></app-header-style-one>

<app-homefour-main-banner></app-homefour-main-banner>
<!--
<app-boxes></app-boxes>

<section class="partner-area pt-100">
    <div class="container">
        <app-partner-style-one></app-partner-style-one>
    </div>
</section>




<section class="instructor-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Make Connections</span>
            <h2>Our Management</h2>
            <p>Our policy that encourages transparency, trust, openness, communication, feedback, and discussion among management people and parents.</p>
        </div>

        <app-instructors-style-one></app-instructors-style-one>
    </div>

    <div id="particles-js-circle-bubble-3"></div>
</section>-->
<app-our-mission> </app-our-mission>

<section class="testimonials-area pt-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Testimonials</span>
            <h2>What Students Says</h2>
        </div>

        <app-feedback-style-two></app-feedback-style-two>
    </div>
</section>

        <app-partner-style-two></app-partner-style-two>

<app-become-instructor-partner></app-become-instructor-partner>

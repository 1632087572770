<section class="become-instructor-partner-area">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-6">
                <div class="become-instructor-partner-content bg-color">
                    <h2>Become an Employee</h2>
                    <p></p>
                    <a routerLink="/login" class="default-btn"><i class='bx bx-plus-circle icon-arrow before'></i><span class="label">Apply Now</span><i class="bx bx-plus-circle icon-arrow after"></i></a>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="become-instructor-partner-image bg-image1 jarallax" data-jarallax='{"speed": 0.3}'>
                    <img src="assets/img/become-instructor.jpg" alt="image">
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="become-instructor-partner-image bg-image2 jarallax" data-jarallax='{"speed": 0.3}'>
                    <img src="assets/img/become-partner.jpg" alt="image">
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="become-instructor-partner-content">
                    <h2>Become a Student</h2>
                    <p>Choose from hundreds of courses...</p>
                    <a routerLink="/login" class="default-btn"><i class='bx bx-plus-circle icon-arrow before'></i><span class="label">Contact Us</span><i class="bx bx-plus-circle icon-arrow after"></i></a>
                </div>
            </div>
        </div>
    </div>
</section>

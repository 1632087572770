<app-header-style-two></app-header-style-two>

<div class="page-title-area item-bg2 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a href="index.html">Home</a></li>
                <li>Category</li>
            </ul>
            <h2>Category List</h2>
        </div>
    </div>
</div>

<section class="courses-categories-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-4 col-sm-6">
                <div class="single-categories-courses-box mb-30">
                    <div class="icon">
                        <i class='bx bx-code-alt'></i>
                    </div>
                    <h3>Web Development</h3>
                    <span>60 Courses</span>
                    <a routerLink="/" class="link-btn"></a>
                </div>
            </div>

            <div class="col-lg-3 col-md-4 col-sm-6">
                <div class="single-categories-courses-box mb-30">
                    <div class="icon">
                        <i class='bx bx-camera'></i>
                    </div>
                    <h3>Photography </h3>
                    <span>21 Courses</span>
                    <a routerLink="/" class="link-btn"></a>
                </div>
            </div>

            <div class="col-lg-3 col-md-4 col-sm-6">
                <div class="single-categories-courses-box mb-30">
                    <div class="icon">
                        <i class='bx bx-layer'></i>
                    </div>
                    <h3>Graphics Design</h3>
                    <span>58 Courses</span>
                    <a routerLink="/" class="link-btn"></a>
                </div>
            </div>

            <div class="col-lg-3 col-md-4 col-sm-6">
                <div class="single-categories-courses-box mb-30">
                    <div class="icon">
                        <i class='bx bxs-flag-checkered'></i>
                    </div>
                    <h3>Web Language</h3>
                    <span>99 Courses</span>
                    <a routerLink="/" class="link-btn"></a>
                </div>
            </div>

            <div class="col-lg-3 col-md-4 col-sm-6">
                <div class="single-categories-courses-box mb-30">
                    <div class="icon">
                        <i class='bx bx-health'></i>
                    </div>
                    <h3>Health & Fitness</h3>
                    <span>21 Courses</span>
                    <a routerLink="/" class="link-btn"></a>
                </div>
            </div>

            <div class="col-lg-3 col-md-4 col-sm-6">
                <div class="single-categories-courses-box mb-30">
                    <div class="icon">
                        <i class='bx bx-line-chart'></i>
                    </div>
                    <h3>Business Studies</h3>
                    <span>49 Courses</span>
                    <a routerLink="/" class="link-btn"></a>
                </div>
            </div>

            <div class="col-lg-3 col-md-4 col-sm-6">
                <div class="single-categories-courses-box mb-30">
                    <div class="icon">
                        <i class='bx bx-bar-chart-alt-2'></i>
                    </div>
                    <h3>Humanities</h3>
                    <span>21 Courses</span>
                    <a routerLink="/" class="link-btn"></a>
                </div>
            </div>

            <div class="col-lg-3 col-md-4 col-sm-6">
                <div class="single-categories-courses-box mb-30">
                    <div class="icon">
                        <i class='bx bxs-drink'></i>
                    </div>
                    <h3>Science</h3>
                    <span>12 Courses</span>
                    <a routerLink="/" class="link-btn"></a>
                </div>
            </div>
        </div>
    </div>

    <div id="particles-js-circle-bubble-2"></div>
</section>

<app-become-instructor-partner></app-become-instructor-partner>
<app-header-style-one></app-header-style-one>

<app-homefive-main-banner></app-homefive-main-banner>

<section class="funfacts-area pt-100">
    <div class="container">
        <div class="funfacts-inner">
            <app-funfacts></app-funfacts>
            <div id="particles-js-circle-bubble"></div>
        </div>
    </div>
</section>

<app-homefive-courses></app-homefive-courses>

<!--
<section class="courses-categories-area pb-70">
    <div class="container">
        <div class="section-title text-left">
            <span class="sub-title">Courses Categories</span>
            <h2>Browse Trending Categories</h2>
            <a routerLink="/courses-category-style-2" class="default-btn"><i class='bx bx-show-alt icon-arrow before'></i><span class="label">View All</span><i class="bx bx-show-alt icon-arrow after"></i></a>
        </div>

        <app-categories-style-two></app-categories-style-two>
    </div>

    <div id="particles-js-circle-bubble-2"></div>
</section>-->

<section class="partner-area pb-100">
    <div class="container">
        <div class="section-title">
            <h2>Our Placement Partners</h2>
        </div>

        <app-partner-style-two></app-partner-style-two>
    </div>
</section>



<section class="testimonials-area pt-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Testimonials</span>
            <h2>What Students Says</h2>
        </div>

        <app-feedback-style-two></app-feedback-style-two>
    </div>
</section>

<app-become-instructor-partner></app-become-instructor-partner>

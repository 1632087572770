<div class="row">
    <div class="col-lg-3 col-sm-6 col-md-4">
        <div class="single-courses-category mb-30">
            <a routerLink="/">
                <i class='bx bx-shape-triangle'></i>
                Technology
            </a>
        </div>
    </div>

    <div class="col-lg-3 col-sm-6 col-md-4">
        <div class="single-courses-category mb-30">
            <a routerLink="/">
                <i class='bx bx-font-family'></i>
                Language
            </a>
        </div>
    </div>

    <div class="col-lg-3 col-sm-6 col-md-4">
        <div class="single-courses-category mb-30">
            <a routerLink="/">
                <i class='bx bxs-drink'></i>
                Science
            </a>
        </div>
    </div>

    <div class="col-lg-3 col-sm-6 col-md-4">
        <div class="single-courses-category mb-30">
            <a routerLink="/">
                <i class='bx bx-first-aid'></i>
                Health
            </a>
        </div>
    </div>

    <div class="col-lg-3 col-sm-6 col-md-4">
        <div class="single-courses-category mb-30">
            <a routerLink="/">
                <i class='bx bx-bar-chart-alt-2'></i>
                Humanities
            </a>
        </div>
    </div>

    <div class="col-lg-3 col-sm-6 col-md-4">
        <div class="single-courses-category mb-30">
            <a routerLink="/">
                <i class='bx bx-briefcase-alt-2'></i>
                Business
            </a>
        </div>
    </div>

    <div class="col-lg-3 col-sm-6 col-md-4">
        <div class="single-courses-category mb-30">
            <a routerLink="/">
                <i class='bx bx-book-reader'></i>
                Math
            </a>
        </div>
    </div>

    <div class="col-lg-3 col-sm-6 col-md-4">
        <div class="single-courses-category mb-30">
            <a routerLink="/">
                <i class='bx bx-target-lock'></i>
                Marketing
            </a>
        </div>
    </div>
</div>
<div class="hero-banner">
     <div class="d-table">

        <div class="d-table-cell">
            <div class="hero-banner-content">
                <span class="sub-title" style="color:black">Since 1985</span>
                <h2 style="color: #ff1949;">அறம் செய்ய விரும்பு </h2>
                <p></p>
            </div>
             <div class="container-fluid">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-12">

                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="hero-banner-image text-center">
                            <img src="assets/img/banner-img1.png" alt="image">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

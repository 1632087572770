<footer class="footer-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget mb-30">
                    <h3>Contact Us</h3>

                    <ul class="contact-us-link">
                        <li>
                            <i class='bx bx-map'></i>
                            <a href="#" target="_blank">St. Michael Group of Institutions, Madurai, TAMILNADU</a>
                        </li>
                        <li>
                            <i class='bx bx-phone-call'></i>
                            <a href="+91 98421 44567">+91 98421 44567</a>
                        </li>
                        <li>
                            <i class='bx bx-envelope'></i>
                            <a href="mailto:info@stmichaelgroup.org">info@stmichaelgroup.org</a>
                        </li>
                    </ul>

                    <ul class="social-link">
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget mb-30">
                    <h3>Support</h3>

                    <ul class="support-link">
                        <li><a routerLink="/">Privacy</a></li>
                        <li><a routerLink="/">FAQ's</a></li>
                        <li><a routerLink="/">Support</a></li>
                        <li><a routerLink="/">Terms</a></li>
                        <li><a routerLink="/">Condition</a></li>
                        <li><a routerLink="/">Policy</a></li>
                    </ul>
                </div>
            </div>
            
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget mb-30">
                    <h3>Newsletter</h3>

                    <div class="newsletter-box">
                      
                        <form class="newsletter-form">
                            <label>Your e-mail address:</label>
                            <input type="email" class="input-newsletter" placeholder="Enter your email" name="EMAIL">
                            <button type="submit">Subscribe</button>
                        </form>
                    </div>
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget mb-30">
                    <h3>Useful Link</h3>

  <ul class="useful-link">
    <li><a href="https://stmichaelgroup.org/" class="d-block" target="_blank">www.stmichaelgroup.org</a></li>
    <li><a href="http://smcet.edu.in/" class="d-block" target="_blank">www.smcet.edu.in</a></li>
    <li><a href="http://www.fmcet.ac.in/" class="d-block" target="_blank">www.fmcet.ac.in</a></li>
    <li><a href="http://www.stmichaelsvga.edu.in/" class="d-block" target="_blank">www.stmichaelsvga.edu.in</a></li>
    <li><a href="http://http://www.stmichaelkkoil.edu.in/" class="d-block" target="_blank">www.stmichaelkkoil.edu.in</a></li>
    <li><a href="https://stmichaelgroup.org/" class="d-block" target="_blank">www.stmichaelschoolmadurai.com</a></li>
    <li><a href="https://rced.in/" class="d-block" target="_blank">www.rced.in</a></li>


                    </ul>
                </div>
            </div>
         
        </div>
    </div>

    <div class="footer-bottom-area">
        <div class="container">
            <div class="logo">
                <a routerLink="/" class="d-inline-block"><img src="assets/img/logo.png" alt="image"></a>
            </div>
            <p><i class='bx bx-copyright'></i>2020 <a routerLink="/" target="_blank">St.Michael Group of Institutions</a> Designed By <a href="https://stmichaelgroup.org/" target="_blank">Sathyanath</a> | All rights reserved.</p>
        </div>
    </div>
</footer>

<div class="go-top"><i class='bx bx-up-arrow-alt'></i></div>

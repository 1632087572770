<app-header-style-one></app-header-style-one>

<div class="page-title-area item-bg2 jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a href="index.html">Home</a></li>
                <li>Contact</li>
            </ul>
            <h2>Contact Us</h2>
        </div>
    </div>
</div>

<section class="contact-info-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="contact-info-box mb-30">
                    <div class="icon">
                        <i class='bx bx-envelope'></i>
                    </div>
                    <h3>Email Here</h3>
                    <p><a href="mailto:info@stmichaelgroup.org">info@stmichaelgroup.org</a></p>
                    <p><a href="mailto:ceo@stmichaelgroup.org">ceo@stmichaelgroup.org</a></p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="contact-info-box mb-30">
                    <div class="icon">
                        <i class='bx bx-map'></i>
                    </div>
                    <h3>Location Here</h3>
                    <p><a href="https://goo.gl/maps/RAupCzJGXyeHhTHa8" target="_blank">St. Michael Group of Institutions, Madurai</a></p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                <div class="contact-info-box mb-30">
                    <div class="icon">
                        <i class='bx bx-phone-call'></i>
                    </div>
                    <h3>Call Here</h3>
                    <p><a href="tel:91 98421 44567">+91 98421 44567</a></p>
                    <p><a href="tel:91 98421 44567">+91 98421 44567</a></p>
                </div>
            </div>
        </div>
    </div>

    <div id="particles-js-circle-bubble-2"></div>
</section>

<section class="contact-area pb-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Contact Us</span>
            <h2>Drop us Message for any Query</h2>
            <p></p>
        </div>

        <div class="contact-form">
            <form id="contactForm">
                <div class="row">
                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <input type="text" name="name" id="name" class="form-control" placeholder="Your Name">
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <input type="email" name="email" id="email" class="form-control" placeholder="Your Email">
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <input type="text" name="phone_number" id="phone_number" class="form-control" placeholder="Your Phone">
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <input type="text" name="msg_subject" id="msg_subject" class="form-control" placeholder="Your Subject">
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12">
                        <div class="form-group">
                            <textarea name="message" class="form-control" id="message" cols="30" rows="5" placeholder="Your Message"></textarea>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12">
                        <button type="submit" class="default-btn"><i class='bx bx-paper-plane icon-arrow before'></i><span class="label">Send Message</span><i class="bx bx-paper-plane icon-arrow after"></i></button>
                    </div>
                </div>
            </form>
        </div>
    </div>

    <div id="particles-js-circle-bubble-3"></div>
    <div class="contact-bg-image"><img src="assets/img/map.png" alt="image"></div>
</section>

<div id="map">
    <div class="mapouter"><div class="gmap_canvas"><iframe class="gmap_iframe" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=1440&amp;height=400&amp;hl=en&amp;q=stmichael school madurai&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe><a href="https://www.kokagames.com/fnf-friday-night-funkin-mods/">Friday Night Funkin Mods</a></div><style>.mapouter{position:relative;text-align:right;width:1440px;height:400px;}.gmap_canvas {overflow:hidden;background:none!important;width:1440px;height:400px;}.gmap_iframe {width:1440px!important;height:400px!important;}</style></div>
</div>

<div class="hero-banner bg-white">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container-fluid">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-12">
                        <div class="hero-banner-content black-color">
                            <span class="sub-title"><h3>Since, 1985</h3></span>
                            <h1 style="color:#ff1949;">அறம் செய்ய விரும்பு !</h1>
                            <p style="color:grey;">We aim at creating next generation leaders by moulding young...</p>
                            <div class="btn-box">
                         <a routerLink="/courses-4-columns-style-1" class="default-btn"><i class='bx bx-move-horizontal icon-arrow before'></i><span class="label">View Institutions</span><i class="bx bx-move-horizontal icon-arrow after"></i></a>
                             <a routerLink="/contact" class="optional-btn">Join with us</a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="hero-banner-image text-center">
                            <img src="assets/img/banner-img3.png" alt="image">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>



<section class="boxes-area">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-3 col-md-6">
                <div class="single-boxes-item bg-color">
                    <h3>Engineering Colleges</h3>
                    <p>“Engineering is not only study of 45 subjects but it is moral studies of intellectual life.” ...</p>
                    <a routerLink="" class="boxes-btn">View Courses<i class='bx bx-plus'></i></a>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-boxes-item">
                    <h3>Schools</h3>
                    <p>“Learn as much as you can while you are young, since life becomes too busy later.” – ...</p>
                    <a routerLink="" class="boxes-btn">View More<i class='bx bx-plus'></i></a>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-boxes-item bg-color">
                    <h3>Polytechnic College</h3>
                    <p>A diploma only proves that you know how to look up answers.</p>
                    <a routerLink="" class="boxes-btn">View Courses<i class='bx bx-plus'></i></a>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-boxes-item">
                    <h3>B.Ed. Colleges</h3>
                    <p>“Education is the most powerful weapon which you can use to change the world”</p>
                    <a routerLink="" class="boxes-btn">View More<i class='bx bx-plus'></i></a>
                </div>
            </div>
        </div>
    </div>
</section>

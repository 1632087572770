<div class="container">
    <div class="row">
        <div class="col-lg-4 col-md-12">
            <div class="section-title text-left">
                <h2>Our Values</h2>
            </div>
        </div>

        <div class="col-lg-8 col-md-12">
            <div class="values-content">
                <h3>A success-oriented learning environment</h3>
                <p>Our institutions have lush green campus to let the students live in an education conducive atmosphere that keeps them enthusiastic while learning. We provide them a glimpse of glorious life they can win for themselves. The experienced faculty members, with their industrial, academic and research skills inspire the students to move ahead in life with a winning attitude and make them expert at their workplace.</p>
                <h3>Academic Excellence and Cultural Diversity</h3>
                <p>The leaders of tomorrow must be able to bridge multiple interests. Our teaching pedagogy ensures that students get exposed to a holistic approach to education, integrating physical, emotional and social development of our students. We complement this with a sound academic base which enables our students to get a head start in an increasingly complex world.</p>
                <h3>Advancing human understanding</h3>
                <p>The knowledge of  social behavior and human understanding forms a solid foundation for a variety of careers. We prepare our students to gain the ability to analyze multiple perspectives on social and cultural change, assess conflicting points of view, and understand the interactions of individuals and societies. Thus, we transform a juvenile into a self-regulated man.</p>
            </div>
        </div>
    </div>
</div>

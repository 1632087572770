<div class="partner-slides owl-carousel owl-theme">
    <div class="single-partner-item">
        <a href="#" target="_blank" class="d-block">
            <img src="assets/img/partner/partner1.png" alt="image">
        </a>
    </div>

    <div class="single-partner-item">
        <a href="#" target="_blank" class="d-block">
            <img src="assets/img/partner/partner2.png" alt="image">
        </a>
    </div>

    <div class="single-partner-item">
        <a href="#" target="_blank" class="d-block">
            <img src="assets/img/partner/partner3.png" alt="image">
        </a>
    </div>

    <div class="single-partner-item">
        <a href="#" target="_blank" class="d-block">
            <img src="assets/img/partner/partner4.png" alt="image">
        </a>
    </div>

    <div class="single-partner-item">
        <a href="#" target="_blank" class="d-block">
            <img src="assets/img/partner/partner5.png" alt="image">
        </a>
    </div>

    <div class="single-partner-item">
        <a href="#" target="_blank" class="d-block">
            <img src="assets/img/partner/partner6.png" alt="image">
        </a>
    </div>
</div>